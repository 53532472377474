import React from "react";
import Layout from "../../components/Layout";
import PageHeader from "../../components/PageHeader";
import Conditions from "../../components/Conditions";
import Information from "../../components/Information";
import TestimonialSlider from "../../components/TestimonialSlider";

const Services = () => {
  return (
    <Layout>
      <Conditions />
      <TestimonialSlider />
      <Information />
    </Layout>
  );
};

export default Services;
